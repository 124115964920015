<template>
  <div class="container">
    <div class="qrcodes" v-for="qrcode in qrcodes" :key="qrcode.location">
      <img class="qrcode" :src="qrcode.url">
      <span class="location">{{ qrcode.location }}</span>
    </div>
  </div> 
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  data() {
    return {
      auth: "sid-5636c00acc2aff21d61e9df272dc4225",
      adPostId: "018b8903-8b21-73c8-efcd-33a56dd8cc0d",
      locations: ["武侯区交子大道"],
      qrcodes: [],
      generateIdx: 0,
    }
  },
  async created() {
    this.getWechatCode();
  },
  methods: {
    /** 参数缩短 */ 
    async handleShortenLink(location) {
      const { auth, adPostId } = this;
      const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
        { action: 1, val: `?adPostId=${ adPostId }&location=${ location }`, useDb: true },
        { Authorization: auth }
      );
      return result;
    },
    async getWechatCode() {
      const _ = this;
      const { locations, generateIdx } = _;
      const location = locations[generateIdx];
      if(generateIdx >= locations.length) return false;
      const shortLink = await this.handleShortenLink(location);
      if(!!shortLink) {
        $fetch
          .post("GetWeixinQRCode", {
            page: 'packages/ad/pages/dewu/index',
            scene: shortLink,
            width: 80,
            is_hyaline: true,
          })
          .then((code) => {
            _.qrcodes.push({ url: "data:image/jpeg;base64, " + code.image,  location });
            if(generateIdx + 1 <= locations.length - 1) {
              _.generateIdx = _.generateIdx + 1;
              _.getWechatCode();
            }
          });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.container {
  @include row;  
  flex-wrap: wrap;

  > .qrcodes {
    @include column(flex-start, center);
    width: 100px;
    margin: 12px;

    > .qrcode {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }

    > .location {
      color: #333;
      font-size: 12px;
      text-align: center;
      margin-top: 12px;
    }
  }
}

</style>
